<template>
    <div>
        <b-row>
            <b-col md="12">
                <div class="estadisticas">
                    <!-- <div class="v-step-0"> -->
                    <CCard>
                        <CCardHeader color="primario" text-color="white">
                            <i class="fas fa-chart-bar fa-md"></i> <span class="h5"> Estadisticas y accesos directos </span>
                            <!-- <b-button size="sm" variant="light" class="float-right" @click="guide = true">
                                    <i class="fas fa-book fa-md"></i> Guía rápida
                                </b-button> -->
                        </CCardHeader>
                        <CCardBody>
                            <!-- {{$store.state.modules}} -->
                            <span class="h6 text-muted">BIENVENIDO/A {{$store.state.user.nombre.toUpperCase() || ' '}} A CONTINUACIÓN SE MUESTRA EL ESTADO DEL SISTEMA:
                            </span>
                            <hr>

                        </CCardBody>
                    </CCard>
                </div>
            </b-col>
            <b-col md="12">
                <CCard>
                    <CCardHeader color="primario" text-color="white">
                        <i class="fas fa-flag fa-md"></i> <span class="h5"> Listado de Usuarios Registrados</span>
                    </CCardHeader>
                    <CCardBody>
                        <b-row>
                            <b-col md="3">
                                <b-form-group label="Registros por página" class="text-muted">
                                    <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col md="6">

                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Busqueda:" class="text-muted">
                                    <b-input-group size="xl">
                                        <b-input-group-prepend is-text>
                                            <i class="fas fa-search  my-0 mx-0"></i>
                                        </b-input-group-prepend>
                                        <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col md="12">
                                <b-table bordered hover show-empty mediun responsive outlined :items="listUsuarios" :fields="camposPanelControl" :current-page="paginaActual" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                    <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                    <template v-slot:cell(nombre)="data">
                                        {{data.item.nombre}}
                                    </template>
                                    <template v-slot:cell(apellidoPaterno)="data">
                                        {{data.item.apellidoPaterno}}
                                    </template>
                                    <template v-slot:cell(apellidoMaterno)="data">
                                        {{data.item.apellidoMaterno}}
                                    </template>
                                    <template v-slot:cell(nroDoc)="data">
                                        {{data.item.usernrodoc}}
                                    </template>
                                    <template v-slot:cell(guia)="data">
                                        <i v-if="data.item.rol == 'guia'" class="fas fa-user-check"></i>
                                    </template>
                                    <template v-slot:cell(administrador)="data">
                                        <i v-if="data.item.rol == 'administrador'" class="fas fa-user-check"></i>
                                    </template>
                                </b-table>
                            </b-col>
                        </b-row>
                    </CCardBody>
                    <CCardFooter>
                        <b-pagination size="sm" v-model="paginaActual" :total-rows="filasTotales" :per-page="porPagina" first-number last-number align="right" class="my-0"></b-pagination>
                    </CCardfooter>
                </CCard>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import firebase from 'firebase';
const dbUsuario = firebase.firestore().collection('users');

export default {
    components: {
        
    },
    data() {
        return {
            listUsuarios: [],

            filasTotales: 1,
            paginaActual: 1,
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],
            filter: null,
            filterOn: [],

            camposPanelControl: [
                {
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "nombre",
                    label: "Nombre",
                    class: "text-center",
                },
                {
                    key: "apellidoPaterno",
                    label: "Apellido Paterno",
                    class: "text-center",
                },
                {
                    key: "apellidoMaterno",
                    label: "Apellido Materno",
                    class: "text-center",
                },
                {
                    key: "nroDoc",
                    label: "Nro. Documento",
                    class: "text-center",
                },
                {
                    key: "guia",
                    label: "¿Guía?",
                    class: "text-center",
                },
                {
                    key: "administrador",
                    label: "¿Administrador?",
                    class: "text-center",
                },
            ],

            guideConfig: {
                steps: [{
                        selector: '.estadisticas',
                        title: 'Panel de Estadísticas',
                        message: 'En el lado izquierdo de su pantalla encontrará el Panel de Estadísticas, que le servirá para ver de forma rápida y visual el estado del sistema.',

                    },
                    {
                        selector: '.estadisticasitem',
                        title: 'Documentos registrados',
                        message: 'En el lado izquierdo de su pantalla encontrará el Panel de Estadísticas, que le servirá para ver de forma rápida y visual el estado del sistema.',
                    }

                ],
                prevButtonText: 'Atras',
                nextButtonText: 'Siguiente',
                skipButtonText: 'Finalizar',
                skipButtonClass: 'text-dark',
                // nextButtonStyle: {
                //     background: '#00000 !important'
                // }
            },
        }
    },
    methods: {
        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length
            this.paginaActual = 1
        },
        listarUsuarios() {
            let me = this;

            dbUsuario
            .where("estado" , "==" , 2)
            .where("idCliente", "==", String(me.$store.state.user.idCliente))
            .where("rol","in" , ["administrador", "guia"])
            // .where("rol","==","administrador")
            .onSnapshot((querySnapshot) => {
                me.listUsuarios = [];
                querySnapshot.forEach((doc) => {
                    me.listUsuarios.push({
                        id: doc.id,
                        nombre: doc.data().nombre,
                        apellidoPaterno: doc.data().apellidoPaterno,
                        apellidoMaterno: doc.data().apellidoMaterno,
                        idTipoDocumento: doc.data().idTipoDocumento,
                        usernrodoc: doc.data().usernrodoc,
                        passnrodoc: doc.data().passnrodoc,
                        telefono: doc.data().telefono,
                        email: doc.data().email,
                        fechaCreacion: doc.data().fechaCreacion,
                        fechaModificacion: doc.data().fechaModificacion,
                        idCliente: doc.data().idCliente,
                        rol: doc.data().rol,
                        fechaNacimiento: doc.data().fechaNacimiento,
                    });
                });

                me.listUsuarios.sort((a, b) => (a.fechaCreacion > b.fechaCreacion) ? -1 : ((b.fechaCreacion > a.fechaCreacion) ? 1 : 0));
            });
        },
    },
    watch: {

    },
    mounted() {
        if (this.$store.state.user) {
            this.listarUsuarios();
        }
    }
}
</script>